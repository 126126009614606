<template>
  <div class="p-20 bg-white" style="min-height: 810px">
    <a-select v-model="filter" @change="jumpPage(1)">
      <a-select-option value="all">全部</a-select-option>
      <a-select-option value="reply">已回答</a-select-option>
      <a-select-option value="noreply">待回答</a-select-option>
    </a-select>
    <div class="py-20">
      <div v-if="questions.length">
        <div>
          <div class="mb-20" v-for="item in questions" :key="item.id">
            <e-question-card @open="open" :dataSource="item" @submit="reply">
              <a-popconfirm
                slot="opt"
                title="是否删除此锦囊?"
                ok-text="删除"
                cancel-text="取消"
                @confirm="delAnswer(item.id)"
              >
                <a-icon type="delete" @click.stop="" />
              </a-popconfirm>
            </e-question-card>
          </div>
        </div>
        <div class="py-20 text-center">
          <a-pagination
            :pageSize="limit"
            @change="jumpPage"
            v-model="page"
            :total="total"
          ></a-pagination>
        </div>
      </div>
      <list-empty v-else></list-empty>
    </div>
    <a-drawer
      title="锦囊详情"
      placement="right"
      width="600"
      :visible="visible"
      @close="visible = false"
    >
      <div class="text-lg font-bold mb-20">{{ current.title }}</div>
      <div class="mb-10 text-xs">
        <a-space align="baseline">
          <div style="width: 60px">所属领域：</div>
          <div class="flex flex-wrap">
            <div
              class="tag1"
              v-for="(tag, index) in current.fields"
              :key="index"
            >
              {{ tag.Name }}
            </div>
          </div>
        </a-space>
      </div>
      <div class="mb-10 text-xs">
        <a-space align="baseline">
          <span>所属行业：</span>
          <div class="flex flex-wrap">
            <div
              class="tag1"
              v-for="(tag, index) in current.industries"
              :key="index"
            >
              {{ tag.Name }}
            </div>
          </div>
        </a-space>
      </div>
      <div
        class="mt-20 text-xs text-999 flex justify-end items-center"
        v-if="role !== 2"
      >
        <div class="span mr-10">锦囊是否对外展示</div>
        <a-switch v-model="current.visible" @change="setQuestionVisible"></a-switch>
      </div>
      <div class="mt-40">
        <div class="flex items-center">
          <div style="width: 24px; max-height: 80px" class="mr-10">
            <img
              src="@/assets/icons/question.svg"
              class="w-full h-full object-contain"
              alt=""
            />
          </div>
          <div class="font-bold">专家锦囊</div>
        </div>

        <div class="mt-20">
          <div v-if="role === 2 && !current.answer">
            <a-form-model :model="model">
              <gf-textarea
                v-focus
                :rows="5"
                :max="300"
                placeholder="请在此输入您的回答"
                prop="answer"
                v-model="model.answer"
                @click.stop=""
              ></gf-textarea>
            </a-form-model>
          </div>
          <div v-else>
            {{ current.answer || "专家暂未给出锦囊" }}
          </div>
        </div>

        <div class="mt-20">
          <div class="text-right" v-if="role === 2 && !current.answer">
            <a-button
              type="primary"
              @click="reply({ ...model, id: current.id })"
              >回复</a-button
            >
          </div>
          <div class="text-center" v-else>
            <a-space>
              <a-button type="primary" ghost @click="visible = false"
                >取消</a-button
              >
              <a-button type="danger" @click="delAnswer(current.id)"
                >删除</a-button
              >
            </a-space>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getQuestions,
  answerQuestion,
  delQuestion,
  setQuestionVisible,
} from "@/api/questions.js";
export default {
  data() {
    return {
      filter: "all",
      limit: 5,
      page: 1,
      total: 0,
      questions: [],
      visible: false,
      current: {},
      model: {},
    };
  },
  created() {
    this.jumpPage(this.page);
  },
  methods: {
    jumpPage(e) {
      this.questionPage = e;
      const limit = this.limit;
      const params = {
        limit,
        offset: (e - 1) * limit,
      };
      params.filter = this.filter;
      getQuestions(params).then((res) => {
        this.questions = res.rows;
        this.total = res.count;
      });
    },
    reply(data) {
      answerQuestion(data.id, { Desc: data.answer })
        .then(() => {
          this.$message.success("回复成功");
          this.visible = false;
          this.jumpPage(this.page);
        })
        .catch(() => {
          this.$message.error("回复失败");
        });
    },
    del(id) {
      delQuestion(id)
        .then(() => {
          this.$message.success("删除提问成功");
          this.visible = false;
          this.jumpPage(this.page);
        })
        .catch(() => {
          this.$message.error("删除提问失败");
        });
    },
    delAnswer(id) {
      if (this.role === 2) {
        answerQuestion(id, { Desc: "" })
          .then(() => {
            this.$message.success("删除回答成功");
            this.jumpPage(this.page);
            this.visible = false;
          })
          .catch(() => {
            this.$message.error("删除回答失败");
          });
      } else {
        this.del(id);
      }
    },
    open(data) {
      this.current = data;
      this.visible = true;
    },
    setQuestionVisible(e) {
      setQuestionVisible(this.current.id, { visible: e })
        .then(() => {
          this.$message.success("设置可见性成功");
        })
        .catch(() => {
          this.$message.error("设置可见性失败");
        });
    },
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
};
</script>

<style scoped>
.tag1 {
  padding: 5px 10px;
  border: 1px solid #eee;
  color: #333;
  line-height: 1;
  display: inline-block;
  height: 26px;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>