import { request, proRequest } from '@/utils/request.js'
import { transformQuestion } from '@/utils/transform';

// 获取专家的问题
export function getExpertQuestions(id, params) {
    return request.get(`/experts/${id}/questions`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformQuestion(row))
        return Promise.resolve(temp)
    })
}

// 获取问题列表
export function getQuestions(params) {
    return request.get(`/user/questions`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformQuestion(row))
        return Promise.resolve(temp)
    })
}

// 创建提问
export function createQuestion(data, params) {
    return request.post('/questions', data, { params })
}

// 获取提问详情
export function getQuestion(id) {
    return request.get(`/questions/${id}`)
}

// 删除提问
export function delQuestion(id) {
    return request.delete(`/questions/${id}`)
}

// 更新提问详情
export function updateQuestion(id, data) {
    return request.put(`/questions/${id}`, data)
}

// 回答提问
export function answerQuestion(id, data) {
    return request.post(`/questions/${id}/answer`, data)
}

// 设置问题可见性
export function setQuestionVisible(id, data) {
    return request.post(`/questions/${id}/visible`, data)
}